import React from 'react';

import { SpanProps } from './Span.types';
import { StyledSpan } from './style';

const Span: React.FC<SpanProps> = (props) => {
  const {
    size,
    sizes,
    font,
    color,
    lineHeight,
    fontWeight,
    fontStyle,
    fontStretch,
    textAlign,
    digital,
    whiteSpace,
    letterSpacing,
    textTransform,
    children,
    ...rest
  } = props;

  return (
    <StyledSpan
      sSize={size}
      sSizes={sizes}
      sFont={font}
      sColor={color}
      sLineHeight={lineHeight}
      sFontWeight={fontWeight}
      sFontStyle={fontStyle}
      sFontStretch={fontStretch}
      sTextAlign={textAlign}
      sDigital={digital}
      sWhiteSpace={whiteSpace}
      sLetterSpacing={letterSpacing}
      sTextTransform={textTransform}
      {...rest}
    >
      {children}
    </StyledSpan>
  );
};

export default Span;
