/* eslint-disable */

import { Global } from '@emotion/react';

function Fonts() {
  return (
    <Global
      styles={`
        @font-face {
            font-family: 'ALS Wagon Bold Narrow';
            src: url('/assets/fonts/als_wagon_bold_narrow.woff2') format('woff2'),
                url('/assets/fonts/als_wagon_bold_narrow.woff') format('woff');
            font-weight: bold;
            font-style: normal;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'ALS Wagon Medium Condensed';
            src: url('/assets/fonts/als_wagon_medium_condensed.woff2') format('woff2'),
                url('/assets/fonts/als_wagon_medium_condensed.woff') format('woff');
            font-weight: 500;
            font-style: normal;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'ALS Wagon Medium Expanded';
            src: url('/assets/fonts/als_wagon_medium_expanded.woff2') format('woff2'),
                url('/assets/fonts/als_wagon_medium_expanded.woff') format('woff');
            font-weight: 500;
            font-style: normal;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'ALS Wagon';
            src: url('/assets/fonts/als_wagon_medium.woff2') format('woff2'),
                url('/assets/fonts/als_wagon_medium.woff') format('woff');
            font-weight: 500;
            font-style: normal;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'ALS Wagon Bold Condensed';
            src: url('/assets/fonts/als_wagon_bold_condensed.woff2') format('woff2'),
                url('/assets/fonts/als_wagon_bold_condensed.woff') format('woff');
            font-weight: bold;
            font-style: normal;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'ALS Wagon Bold Expanded';
            src: url('/assets/fonts/als_wagon_bold_expanded.woff2') format('woff2'),
                url('/assets/fonts/als_wagon_bold_expanded.woff') format('woff');
            font-weight: bold;
            font-style: normal;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'ALS Wagon';
            src: url('/assets/fonts/als_wagon_black.woff2') format('woff2'),
                url('/assets/fonts/als_wagon_black.woff') format('woff');
            font-weight: 900;
            font-style: normal;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'ALS Wagon Black Condensed';
            src: url('/assets/fonts/als_wagon_black_condensed.woff2') format('woff2'),
                url('/assets/fonts/als_wagon_black_condensed.woff') format('woff');
            font-weight: 900;
            font-style: normal;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'ALS Wagon';
            src: url('/assets/fonts/als_wagon_bold.woff2') format('woff2'),
                url('/assets/fonts/als_wagon_bold.woff') format('woff');
            font-weight: bold;
            font-style: normal;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'ALS Wagon Black Narrow';
            src: url('/assets/fonts/als_wagon_black_narrow.woff2') format('woff2'),
                url('/assets/fonts/als_wagon_black_narrow.woff') format('woff');
            font-weight: 900;
            font-style: normal;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'ALS Wagon Black Expanded';
            src: url('/assets/fonts/als_wagon_black_expanded.woff2') format('woff2'),
                url('/assets/fonts/als_wagon_black_expanded.woff') format('woff');
            font-weight: 900;
            font-style: normal;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'ALS Wagon Narrow';
            src: url('/assets/fonts/als_wagon_regular_narrow.woff2') format('woff2'),
                url('/assets/fonts/als_wagon_regular_narrow.woff') format('woff');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'ALS Wagon Expanded';
            src: url('/assets/fonts/als_wagon_regular_expanded.woff2') format('woff2'),
                url('/assets/fonts/als_wagon_regular_expanded.woff') format('woff');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'ALS Wagon Medium Narrow';
            src: url('/assets/fonts/als_wagon_medium_narrow.woff2') format('woff2'),
                url('/assets/fonts/als_wagon_medium_narrow.woff') format('woff');
            font-weight: 500;
            font-style: normal;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'ALS Wagon';
            src: url('/assets/fonts/als_wagon_regular.woff2') format('woff2'),
                url('/assets/fonts/als_wagon_regular.woff') format('woff');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'ALS Wagon Condensed';
            src: url('/assets/fonts/als_wagon_regular_condensed.woff2') format('woff2'),
                url('/assets/fonts/als_wagon_regular_condensed.woff') format('woff');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
        }
        @font-face {
            font-family: 'MTS';
            src: url('/assets/fonts/MTSExtended-Black.woff2') format('woff2'),
            url('/assets/fonts/MTSExtended-Black.woff') format('woff');
            font-weight: 800;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'MTS';
            src: url('/assets/fonts/MTSExtended-Bold.woff2') format('woff2'),
            url('/assets/fonts/MTSExtended-Bold.woff') format('woff');
            font-weight: bold;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'MTS';
            src: url('/assets/fonts/MTSExtended-Medium.woff2') format('woff2'),
            url('/assets/fonts/MTSExtended-Medium.woff') format('woff');
            font-weight: 400;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'MTS';
            src: url('/assets/fonts/MTSExtended-Regular.woff2') format('woff2'),
            url('/assets/fonts/MTSExtended-Regular.woff') format('woff');
            font-weight: 200;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'MTS Ultra';
            src: url('/assets/fonts/MTSUltraExtended-Black.woff2') format('woff2'),
            url('/assets/fonts/MTSUltraExtended-Black.woff') format('woff');
            font-weight: 800;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'MTS Ultra';
            src: url('/assets/fonts/MTSUltraExtended-Bold.woff2') format('woff2'),
            url('/assets/fonts/MTSUltraExtended-Bold.woff') format('woff');
            font-weight: bold;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'MTS Ultra';
            src: url('/assets/fonts/MTSUltraExtended-Light.woff2') format('woff2'),
            url('/assets/fonts/MTSUltraExtended-Light.woff') format('woff');
            font-weight: 100;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'MTS Ultra';
            src: url('/assets/fonts/MTSUltraExtended-Regular.woff2') format('woff2'),
            url('/assets/fonts/MTSUltraExtended-Regular.woff') format('woff');
            font-weight: 200;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'MTS Wide';
            src: url('/assets/fonts/MTSWide-Black.ttf') format('truetype');
            font-weight: 800;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'MTS Wide';
            src: url('/assets/fonts/MTSWide-Bold.ttf') format('truetype');
            font-weight: bold;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'MTS Wide';
            src: url('/assets/fonts/MTSWide-Light.ttf') format('truetype');
            font-weight: 100;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'MTS Wide';
            src: url('/assets/fonts/MTSWide-Regular.ttf') format('truetype');
            font-weight: 200;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'MTS Wide';
            src: url('/assets/fonts/MTSWide-Medium.ttf') format('truetype');
            font-weight: 600;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'MTS Text';
            src: url('/assets/fonts/MTSText-Black.ttf') format('truetype');
            font-weight: 800;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'MTS Text';
            src: url('/assets/fonts/MTSText-Bold.ttf') format('truetype');
            font-weight: bold;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'MTS Text';
            src: url('/assets/fonts/MTSText-Medium.ttf') format('truetype');
            font-weight: 400;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'MTS Text';
            src: url('/assets/fonts/MTSText-Regular.ttf') format('truetype');
            font-weight: 200;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'MTS Compact';
            src: url('/assets/fonts/MTSCompact-Black.ttf') format('truetype');
            font-weight: 800;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'MTS Compact';
            src: url('/assets/fonts/MTSCompact-Bold.ttf') format('truetype');
            font-weight: bold;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'MTS Compact';
            src: url('/assets/fonts/MTSCompact-Medium.ttf') format('truetype');
            font-weight: 400;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'MTS Compact';
            src: url('/assets/fonts/MTSCompact-Regular.ttf') format('truetype');
            font-weight: 200;
            font-style: normal;
            font-display: swap;
        }
        @font-face {
            font-family: 'TELE2 Rooftop Medium';
            src: url('/assets/fonts/t2-Rooftop-Medium.woff2') format('woff2'),
            url('/assets/fonts/t2-Rooftop-Medium.woff') format('woff'),
            url('/assets/fonts/t2-Rooftop-Medium.otf') format('opentype');
            font-weight: 400;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'TELE2 Rooftop Regular';
            src: url('/assets/fonts/t2-Rooftop-Regular.woff2') format('woff2'),
            url('/assets/fonts/t2-Rooftop-Regular.woff') format('woff'),
            url('/assets/fonts/t2-Rooftop-Regular.otf') format('opentype');
            font-weight: 200;
            font-style: normal;
            font-display: swap;
        }
        @font-face {
            font-family: 'TELE2 HalvarBreit ExtraBold';
            src: url('/assets/fonts/T2HalvarBreit-ExtraBold.woff2') format('woff2'),
            url('/assets/fonts/T2HalvarBreit-ExtraBold.woff') format('woff'),
            url('/assets/fonts/T2HalvarBreit-ExtraBold.otf') format('opentype')
            url('/assets/fonts/T2HalvarBreit-ExtraBold.ttf') format('truetype');
            font-weight: bolder;
            font-style: normal;
            font-display: swap;
        }
        @font-face {
            font-family: 'TELE2 HalvarStencilBreit ExtraBold';
            src: url('/assets/fonts/T2HalvarStencilBreit-ExtraBoldMidGap.ttf') format('truetype');
            font-weight: bolder;
            font-style: normal;
            font-display: swap;
        }
      `}
    />
  );
}

export default Fonts;
