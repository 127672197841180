import { mediaSizes, colors, fonts, display, gradients } from 'components/constants';

export default {
  display: { ...display },
  fontFamily: fonts.primary,
  fontWeight: 500,
  height: {
    [mediaSizes.s]: { s: 40, m: 56, l: 80 },
    [mediaSizes.m]: { s: 40, m: 56, l: 80 },
    [mediaSizes.l]: { s: 40, m: 56, l: 80 },
  },
  lineHeight: {
    [mediaSizes.s]: { s: 16, m: 24, l: 32 },
    [mediaSizes.m]: { s: 16, m: 24, l: 32 },
    [mediaSizes.l]: { s: 16, m: 24, l: 32 },
  },
  fontSize: {
    [mediaSizes.s]: { s: 14, m: 16, l: 20 },
    [mediaSizes.m]: { s: 14, m: 16, l: 20 },
    [mediaSizes.l]: { s: 14, m: 16, l: 20 },
  },
  borderRadius: {
    rounded: {
      [mediaSizes.s]: { s: 20, m: 28, l: 40 },
      [mediaSizes.m]: { s: 20, m: 28, l: 40 },
      [mediaSizes.l]: { s: 20, m: 28, l: 40 },
    },
    normal: {
      [mediaSizes.s]: { s: 6, m: 8, l: 12 },
      [mediaSizes.m]: { s: 6, m: 8, l: 12 },
      [mediaSizes.l]: { s: 6, m: 8, l: 12 },
    },
  },
  padding: {
    [mediaSizes.s]: { s: [6, 15], m: [15, 19], l: [23, 31] },
    [mediaSizes.m]: { s: [6, 15], m: [15, 19], l: [23, 31] },
    [mediaSizes.l]: { s: [6, 15], m: [15, 19], l: [23, 31] },
  },

  mtsOnline: {
    fontFamily: fonts.extendedMts,
    fontWeight: 500,
    fontSize: {
      [mediaSizes.s]: { s: 10, m: 12, l: 12 },
      [mediaSizes.m]: { s: 14, m: 14, l: 16 },
      [mediaSizes.l]: { s: 14, m: 14, l: 16 },
    },
    backgroundColors: {
      normal: colors.green,
      focused: colors.white,
      hovered: colors.white,
      pressed: colors.white,
      disabled: colors.disabled,
    },
    borderColors: {
      normal: colors.transparent,
      focused: colors.transparent,
      hovered: colors.transparent,
      pressed: colors.transparent,
      disabled: colors.transparent,
    },
    colors: {
      normal: colors.blue,
      focused: colors.blue,
      hovered: colors.blue,
      pressed: colors.blue,
      disabled: colors.white,
    },
    border: 1,
  },

  primary: {
    backgroundColors: {
      normal: colors.green,
      focused: colors.white,
      hovered: colors.white,
      pressed: colors.white,
      disabled: colors.disabled,
    },
    borderColors: {
      normal: colors.transparent,
      focused: colors.transparent,
      hovered: colors.transparent,
      pressed: colors.transparent,
      disabled: colors.transparent,
    },
    colors: {
      normal: colors.blue,
      focused: colors.blue,
      hovered: colors.blue,
      pressed: colors.blue,
      disabled: colors.white,
    },
    border: 1,
  },
  dark: {
    backgroundColors: {
      normal: colors.black,
      focused: colors.black,
      hovered: colors.black40,
      pressed: colors.black40,
      disabled: colors.black20,
    },
    borderColors: {
      normal: colors.transparent,
      focused: colors.transparent,
      hovered: colors.transparent,
      pressed: colors.transparent,
      disabled: colors.transparent,
    },
    colors: {
      normal: colors.white,
      focused: colors.white,
      hovered: colors.white,
      pressed: colors.white,
      disabled: colors.white,
    },
    border: 1,
  },
  darkLink: {
    backgroundColors: {
      normal: colors.white,
      focused: colors.white,
      hovered: colors.white,
      pressed: colors.white,
      disabled: colors.disabled,
    },
    borderColors: {
      normal: colors.transparent,
      focused: colors.transparent,
      hovered: colors.transparent,
      pressed: colors.transparent,
      disabled: colors.transparent,
    },
    colors: {
      normal: colors.black,
      focused: colors.black,
      hovered: colors.black,
      pressed: colors.black,
      disabled: colors.black,
    },
    border: 1,
  },
  secondary: {
    backgroundColors: {
      normal: colors.transparent,
      focused: colors.grey40,
      hovered: colors.grey40,
      pressed: colors.grey80,
      disabled: colors.grey20,
    },
    borderColors: {
      normal: colors.transparent,
      focused: colors.transparent,
      hovered: colors.transparent,
      pressed: colors.transparent,
      disabled: colors.transparent,
    },
    colors: {
      normal: colors.blue,
      focused: colors.blue,
      hovered: colors.blue,
      pressed: colors.blue,
      disabled: colors.grey20,
    },
    border: 1,
  },
  tertiary: {
    backgroundColors: {
      normal: gradients.lightGrey,
      focused: gradients.greenSoftBlue,
      hovered: gradients.greenSoftBlue,
      pressed: gradients.greenSoftBlue,
      disabled: colors.grey20,
    },
    borderColors: {
      normal: colors.transparent,
      focused: colors.transparent,
      hovered: colors.transparent,
      pressed: colors.transparent,
      disabled: colors.transparent,
    },
    colors: {
      normal: colors.blue,
      focused: colors.blue,
      hovered: colors.blue,
      pressed: colors.blue,
      disabled: colors.grey20,
    },
    border: 0,
  },
  link: {
    backgroundColors: {
      normal: colors.transparent,
      focused: colors.transparent,
      hovered: colors.transparent,
      pressed: colors.transparent,
      disabled: colors.transparent,
    },
    borderColors: {
      normal: colors.transparent,
      focused: colors.transparent,
      hovered: colors.transparent,
      pressed: colors.transparent,
      disabled: colors.transparent,
    },
    colors: {
      normal: colors.blue,
      focused: colors.blue70,
      hovered: colors.blue70,
      pressed: colors.blue70,
      disabled: colors.grey20,
    },
    border: 0,
  },
  opacity: {
    backgroundColors: {
      normal: colors.white16,
      focused: colors.white24,
      hovered: colors.white24,
      pressed: colors.white24,
      disabled: colors.grey20,
    },
    borderColors: {
      normal: colors.transparent,
      focused: colors.transparent,
      hovered: colors.transparent,
      pressed: colors.transparent,
      disabled: colors.transparent,
    },
    colors: {
      normal: colors.white,
      focused: colors.white,
      hovered: colors.white,
      pressed: colors.white,
      disabled: colors.grey20,
    },
    border: 0,
  },
  negative: {
    backgroundColors: {
      normal: colors.red08,
      focused: colors.red30,
      hovered: colors.red30,
      pressed: colors.red30,
      disabled: colors.grey10,
    },
    borderColors: {
      normal: colors.transparent,
      focused: colors.transparent,
      hovered: colors.transparent,
      pressed: colors.transparent,
      disabled: colors.transparent,
    },
    colors: {
      normal: colors.red,
      focused: colors.red,
      hovered: colors.red,
      pressed: colors.red,
      disabled: colors.grey50,
    },
    border: 1,
  },
  negativeLink: {
    backgroundColors: {
      normal: colors.transparent,
      focused: colors.transparent,
      hovered: colors.transparent,
      pressed: colors.transparent,
      disabled: colors.grey10,
    },
    borderColors: {
      normal: colors.transparent,
      focused: colors.transparent,
      hovered: colors.transparent,
      pressed: colors.transparent,
      disabled: colors.transparent,
    },
    colors: {
      normal: colors.red80,
      focused: colors.red80,
      hovered: colors.red80,
      pressed: colors.red,
      disabled: colors.grey50,
    },
    border: 1,
  },

  gradientDark: {
    backgroundColors: {
      normal: gradients.greenBlue,
      focused: gradients.blue,
      hovered: gradients.blue,
      pressed: gradients.blue,
      disabled: colors.grey20,
    },
    borderColors: {
      normal: colors.transparent,
      focused: colors.transparent,
      hovered: colors.transparent,
      pressed: colors.transparent,
      disabled: colors.transparent,
    },
    colors: {
      normal: colors.white,
      focused: colors.white,
      hovered: colors.white,
      pressed: colors.white,
      disabled: colors.white,
    },
    border: 0,
  },

  loading: {
    color: colors.white,
  },
};
