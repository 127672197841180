import { mediaSizes, colors, fonts, fontWeights } from 'components/constants';

export default {
  label: {
    fontFamily: fonts.secondary,
    position: {
      [mediaSizes.s]: {
        s: { top: 16, left: 12, right: 12 },
        m: { top: 18, left: 12, right: 12 },
        l: { top: 20, left: 12, right: 12 },
      },
      [mediaSizes.m]: {
        s: { top: 16, left: 12, right: 12 },
        m: { top: 18, left: 12, right: 12 },
        l: { top: 20, left: 12, right: 12 },
      },
      [mediaSizes.l]: {
        s: { top: 16, left: 12, right: 12 },
        m: { top: 18, left: 12, right: 12 },
        l: { top: 20, left: 12, right: 12 },
      },
    },
    fontSize: {
      normal: {
        [mediaSizes.s]: { s: 16, m: 16, l: 16 },
        [mediaSizes.m]: { s: 16, m: 16, l: 16 },
        [mediaSizes.l]: { s: 16, m: 16, l: 16 },
      },
      small: {
        [mediaSizes.s]: { s: 10, m: 10, l: 10 },
        [mediaSizes.m]: { s: 10, m: 10, l: 10 },
        [mediaSizes.l]: { s: 10, m: 10, l: 10 },
      },
    },
    lineHeight: {
      normal: {
        [mediaSizes.s]: { s: 24, m: 24, l: 24 },
        [mediaSizes.m]: { s: 24, m: 24, l: 24 },
        [mediaSizes.l]: { s: 24, m: 24, l: 24 },
      },
      small: {
        [mediaSizes.s]: { s: 16, m: 16, l: 16 },
        [mediaSizes.m]: { s: 16, m: 16, l: 16 },
        [mediaSizes.l]: { s: 16, m: 16, l: 16 },
      },
    },
    fontWeight: {
      normal: 400,
      small: 500,
    },
    colors: {
      primary: {
        disabled: colors.grey50,
        focused: colors.grey50,
        error: colors.grey50,
        normal: colors.grey50,
      },
      dark: {
        disabled: colors.black20,
        focused: colors.black20,
        error: colors.black,
        normal: colors.black,
      },
      secondary: {
        disabled: colors.grey50,
        focused: colors.grey50,
        error: colors.grey50,
        normal: colors.grey50,
      },
      tertiary: {
        disabled: colors.white,
        focused: colors.white,
        error: colors.white,
        normal: colors.white,
      },
    },
  },

  element: {
    fontFamily: fonts.secondary,
    height: {
      [mediaSizes.s]: { s: 40, m: 56, l: 64 },
      [mediaSizes.m]: { s: 40, m: 56, l: 64 },
      [mediaSizes.l]: { s: 40, m: 56, l: 64 },
    },
    padding: {
      [mediaSizes.s]: {
        vertical: {
          normal: { s: [8, 8], m: [15, 15], l: [23, 23] },
          withLabel: { s: [25, 5], m: [22, 10], l: [33, 13] },
        },
        horizontal: {
          normal: { s: [14, 14], m: [10, 10], l: [10, 10] },
          withIcon: { s: [12, 55], m: [12, 55], l: [12, 55] },
        },
      },
      [mediaSizes.m]: {
        vertical: {
          normal: { s: [8, 8], m: [15, 15], l: [23, 23] },
          withLabel: { s: [25, 5], m: [22, 10], l: [33, 13] },
        },
        horizontal: {
          normal: { s: [14, 14], m: [10, 10], l: [10, 10] },
          withIcon: { s: [12, 55], m: [12, 55], l: [12, 55] },
        },
      },
      [mediaSizes.l]: {
        vertical: {
          normal: { s: [8, 8], m: [15, 15], l: [23, 23] },
          withLabel: { s: [25, 5], m: [22, 10], l: [33, 13] },
        },
        horizontal: {
          normal: { s: [14, 14], m: [10, 10], l: [10, 10] },
          withIcon: { s: [12, 55], m: [12, 55], l: [12, 55] },
        },
      },
    },
    lineHeight: {
      [mediaSizes.s]: { s: 21.6, m: 21.6, l: 21.6 },
      [mediaSizes.m]: { s: 21.6, m: 21.6, l: 21.6 },
      [mediaSizes.l]: { s: 21.6, m: 21.6, l: 21.6 },
    },
    fontSize: {
      [mediaSizes.s]: { s: 16, m: 16, l: 16 },
      [mediaSizes.m]: { s: 16, m: 16, l: 16 },
      [mediaSizes.l]: { s: 16, m: 16, l: 16 },
    },
    fontWeights: {
      primary: 400,
      secondary: 500,
      warning: 400,
    },
    colors: {
      primary: {
        disabled: colors.grey20,
        focused: colors.grey,
        error: colors.red,
        normal: colors.grey,
      },
      dark: {
        disabled: colors.black20,
        focused: colors.black20,
        error: colors.black,
        normal: colors.black,
      },
      secondary: {
        disabled: colors.grey20,
        focused: colors.grey,
        error: colors.grey,
        normal: colors.grey,
      },
      warning: {
        disabled: colors.lightRed237,
        focused: colors.lightRed237,
        error: colors.lightRed237,
        normal: colors.lightRed237,
      },
      tertiary: {
        disabled: colors.white,
        focused: colors.white,
        error: colors.white,
        normal: colors.white,
      },
    },
    backgroundColors: {
      primary: {
        disabled: colors.lightGrey,
        focused: colors.lightGrey,
        error: colors.lightGrey,
        normal: colors.lightGrey,
      },
      dark: {
        disabled: colors.black20,
        focused: colors.black20,
        error: colors.black,
        normal: colors.black,
      },
      secondary: {
        disabled: colors.white,
        focused: colors.white,
        error: colors.white,
        normal: colors.white,
      },
      warning: {
        disabled: colors.lightRed252,
        focused: colors.lightRed252,
        error: colors.lightRed252,
        normal: colors.lightRed252,
      },
      tertiary: {
        disabled: colors.white32,
        focused: colors.white32,
        error: colors.white32,
        normal: colors.white32,
      },
    },
    borderColors: {
      primary: {
        disabled: colors.transparent,
        focused: colors.transparent,
        error: colors.red,
        normal: colors.transparent,
      },
      dark: {
        disabled: colors.transparent,
        focused: colors.transparent,
        error: colors.red,
        normal: colors.transparent,
      },
      secondary: {
        disabled: colors.transparent,
        focused: colors.transparent,
        error: colors.red,
        normal: colors.transparent,
      },
      tertiary: {
        disabled: colors.transparent,
        focused: colors.transparent,
        error: colors.red,
        normal: colors.transparent,
      },
      warning: {
        disabled: colors.transparent,
        focused: colors.transparent,
        error: colors.red,
        normal: colors.transparent,
      },
    },
  },

  icon: {
    right: {
      [mediaSizes.s]: { s: 16, m: 16, l: 16 },
      [mediaSizes.m]: { s: 16, m: 16, l: 16 },
      [mediaSizes.l]: { s: 16, m: 16, l: 16 },
    },
    colors: {
      normal: colors.grey30,
      hover: colors.grey80,
    },
  },
  changeLabel: {
    font: fonts.primary,
    fontWeight: fontWeights.medium,
    fontSize: 16,
    colors: {
      normal: colors.blue,
      hover: colors.blue80,
    },
  },
};
