import styled from 'styled-components';

import { mediaSizes } from 'components/constants';

import theme from './theme';

export const StyledForm = styled.form.attrs({
  autoComplete: 'off',
})`
  position: relative;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: stretch;
`;

export const StyledFormInner = styled.div`
  position: relative;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
`;

export const StyledFormField = styled.div`
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 100%;
`;

export const StyledFormFieldLabel = styled.label`
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 100%;
  font-size: ${theme.field.label.fontSize}px;
  line-height: ${theme.field.label.lineHeight}px;
  color: ${theme.field.label.color};
  padding-bottom: ${theme.field.label.paddingBottom}px;
`;

export const StyledFormFieldDescription = styled.span`
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 100%;
  font-size: ${theme.field.description.fontSize}px;
  line-height: ${theme.field.description.lineHeight}px;
  color: ${theme.field.description.color};
  padding-top: ${theme.field.description.paddingTop}px;
  padding-bottom: ${theme.field.description.paddingBottom}px;
`;

function getErrorPadding(mediasize: string) {
  const padding = theme.field.error.padding[mediasize];
  return `${padding[0]}px ${padding[1]}px ${padding[2]}px 0`;
}

export const StyledFormFieldError = styled.div`
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 100%;
  font-size: ${theme.field.error.fontSize}px;
  line-height: ${theme.field.error.lineHeight}px;
  color: ${theme.field.error.color};
  background: ${theme.field.error.backgroundColor};
  padding: ${getErrorPadding(mediaSizes.s)};
`;

export const StyledFormSubmitButton = styled.button.attrs({
  type: 'submit',
  tabIndex: -1,
})`
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  border: none;
  background: transparent;
  outline: none;
  opacity: 0;
  z-index: -1;
`;
