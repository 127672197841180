import React from 'react';
import { types, Instance, flow } from 'mobx-state-tree';

import { clearLocalStorage, setTokens } from 'api/utils';
import { setMobileNativeEvent } from 'utils/setMobileNativeEvent';
import { checkIncludes, createStoreError, getSettings, getTokenUserId } from 'utils/helper';

import Socket from 'api/socket';
import { Cart, store as apiCartStore } from 'api/cart';
import { IOauth2AccessToken } from 'api/customer';
import { channels, mobileNativeEvents, commonChannelsWithoutAuth } from 'api/constants';
import { Profile, store as apiProfileStore } from 'api/profile';

import { mobileNativeEventsList, ROUTES, STORAGE_KEYS } from './constants';
import { Actions, Containers, ContainerStore, OpenAuthParams, Params } from './types';
import { getMobileNativeEventsVisible } from './utils';
import { getTokenExpTime } from './utils/auth';
import { refreshToken } from './api/axios';

let refreshTimer: NodeJS.Timeout;

const Error = types.model({
  status: types.maybeNull(types.number),
  message: types.maybeNull(types.string),
});

const Auth = types.model({
  isOpenedAuth: types.maybeNull(types.boolean),
  redirectTo: types.maybeNull(types.string),
  reload: types.maybeNull(types.boolean),
  closingOnOutClick: types.maybeNull(types.boolean),
  withoutCloseIcon: types.maybeNull(types.boolean),
});

const Data = types.model({
  profile: types.maybeNull(Profile),
  cartItems: types.maybeNull(Cart),
});

const Settings = types.model({
  os: types.maybeNull(types.string),
  version: types.maybeNull(types.string),
  channel: types.maybeNull(types.string),
});

export interface ISettings extends Instance<typeof Settings> {}

export const Store = types
  .model({
    mounted: types.boolean,
    initialized: types.boolean,
    isAuthorized: types.boolean,
    isMobileApp: types.maybeNull(types.boolean),
    isLoading: types.maybeNull(types.boolean),
    pathname: types.maybeNull(types.string),
    goTo: types.maybeNull(types.string),
    error: types.maybeNull(Error),
    settings: types.maybeNull(Settings),
    captions: types.maybeNull(types.frozen()),
    auth: types.maybeNull(Auth),
    data: Data,
  })
  .views((self) => ({
    get canOpenSocket() {
      return !window.location.pathname.includes(ROUTES.SELL_VISUAL_DIAGNOSTIC);
    },

    get isMobileRoute() {
      return self.isMobileApp;
    },

    get isOpenedAuth() {
      return self.auth?.isOpenedAuth;
    },

    get isChannel() {
      return Object.keys(channels).some((el) => channels[el] === self.settings.channel);
    },

    get isCommonChannelsWithoutAuth() {
      return Object.keys(commonChannelsWithoutAuth).some(
        (el) => commonChannelsWithoutAuth[el] === self.settings.channel,
      );
    },

    get getCurrentChannel() {
      return self.settings?.channel;
    },

    get isMtsOnlineChannel() {
      return this.getCurrentChannel === channels.mts_online;
    },

    get isT2Channel() {
      return this.getCurrentChannel === channels.t2;
    },

    get onlyContent() {
      return this.isMobileRoute || this.isChannel;
    },

    get mobileNativeEventsVisible() {
      if (!self.isMobileApp) return {};
      return getMobileNativeEventsVisible(mobileNativeEventsList, self.settings);
    },

    get isBlue() {
      const isBlue = checkIncludes(self.pathname, '/sell', '/about-us', '/report/buy', '/not-found');
      const backgroundColor = isBlue ? '#4801FF' : '#F8F8F8';
      if (this.isMtsOnlineChannel) {
        document.body.style.backgroundColor = '#F2F3F7';
      } else if (this.isT2Channel) {
        document.body.style.backgroundColor = '#FFFFFF';
      } else {
        document.body.style.backgroundColor = backgroundColor;
      }
      if (this.mobileNativeEventsVisible[mobileNativeEvents.setBackgroundColor]) {
        setMobileNativeEvent(mobileNativeEvents.setBackgroundColor, {
          backgroundColor: this.isT2Channel ? '#FFFFFF' : this.isMtsOnlineChannel ? '#F2F3F7' : backgroundColor,
        });
      }
      return isBlue && !this.isMtsOnlineChannel && !this.isT2Channel;
    },
  }))
  .volatile<{ containers: Containers; channel: any; externalActions: Actions; onAuthComplete: () => void }>(() => ({
    containers: {},
    channel: null,
    externalActions: null,
    onAuthComplete: null,
  }))
  .actions((self) => ({
    connectionError: () => {},
    connectionSuccess: () => {
      const userId = getTokenUserId(STORAGE_KEYS.ACCESS_TOKEN);
      self.channel.subscribe(userId, () => {});
    },
  }))
  .actions((self) => {
    const setError = (error: any) => {
      const storeError = createStoreError(error);
      console.log(storeError); // eslint-disable-line
      if ([401, 403].includes(error?.response?.status)) {
        onSignOut();
        if (!self.isMobileApp) onOpenAuth({ reload: true });
      }
      self.error = storeError;
    };

    const updatePathname = (pathname: string) => {
      self.pathname = pathname;
    };

    const setSettings = () => {
      self.settings = getSettings();
    };

    const openSocket = () => {
      self.channel = Socket.getInstance();
      self.channel.connect({}, self.connectionSuccess, self.connectionError);
    };

    const closeSocket = () => {
      self.channel?.disconnect();
      self.channel = null;
      Socket.removeInstance();
    };

    const handleGoTo = (goTo: string) => {
      self.goTo = goTo;
    };

    const clearGoTo = () => {
      self.goTo = null;
    };

    const onOpenAuth = ({
      redirectTo,
      reload,
      onComplete,
      closingOnOutClick,
      withoutCloseIcon,
    }: OpenAuthParams = {}) => {
      self.auth = { isOpenedAuth: true, redirectTo, reload, closingOnOutClick, withoutCloseIcon };
      self.onAuthComplete = onComplete;
    };

    const onCloseAuth = () => {
      self.auth = null;
    };

    const getProfile = flow(function* getProfile() {
      self.data.profile = yield apiProfileStore.getProfile();
    });

    const mergeCart = flow(function* mergeCart() {
      try {
        const cartId = localStorage.getItem('SET_CART_ID');
        const result = yield apiCartStore.mergeCart(cartId);
        self.externalActions.setShopizerCartID(result.cartCode);
        self.externalActions.getCart(result.cartCode);
      } catch (e) {
        console.log(e); // eslint-disable-line
      }
    });

    const onSignIn = flow(function* onSignIn(data: IOauth2AccessToken) {
      try {
        self.isAuthorized = true;
        setTokens({ accessToken: data.accessToken, refreshToken: data.refreshToken });
        localStorage.removeItem(STORAGE_KEYS.DIAGNOSTIC_TOKEN);
        self.externalActions.setUser(data);
        self.externalActions.setIsAuthorized(true);
        if (!self.isChannel) {
          yield getProfile();
          yield mergeCart();
        }
      } catch (error) {
        setError(error);
      } finally {
        if (self.auth?.redirectTo) handleGoTo(self.auth.redirectTo);
        if (self.auth?.reload) window.location.reload();
        if (self.onAuthComplete) self.onAuthComplete();
        onCloseAuth();
        checkToken();
      }
    });

    const onSignOut = () => {
      if (self.isMobileApp) setMobileNativeEvent('logout');
      clearLocalStorage();
      closeSocket();
      self.isAuthorized = false;
      self.data.profile = null;
      self.externalActions.deleteAllFromCart();
      self.externalActions.setUser(null);
      self.externalActions.setIsAuthorized(false);
    };

    const refreshAccessToken = async () => {
      await refreshToken();
      const accessTokenExpTime = getTokenExpTime(STORAGE_KEYS.ACCESS_TOKEN);
      if (accessTokenExpTime - 1000 < 2147483647) {
        refreshTimer = setTimeout(refreshAccessToken, accessTokenExpTime - 10000);
      }
    };

    const checkToken = flow(function* checkToken() {
      try {
        const accessTokenExpTime = getTokenExpTime(STORAGE_KEYS.ACCESS_TOKEN);
        const refreshTokenExpTime = getTokenExpTime(STORAGE_KEYS.REFRESH_TOKEN);
        if (refreshTokenExpTime <= 0) {
          if (!self.isMobileApp && accessTokenExpTime <= 0) {
            self.isAuthorized = false;
            onSignOut();
          }
        } else if (accessTokenExpTime - 10000 > 0) {
          if (accessTokenExpTime - 1000 < 2147483647) {
            setTimeout(refreshAccessToken, accessTokenExpTime - 10000);
          }
        } else {
          yield refreshAccessToken();
          self.isAuthorized = true;
        }
        self.externalActions.setIsAuthorized(self.isAuthorized);
      } catch (e) {
        setError(e);
      } finally {
        if (!self.channel && self.isAuthorized && self.canOpenSocket) openSocket();
        self.initialized = true;
      }
    });

    return {
      setError,
      updatePathname,
      setSettings,
      handleGoTo,
      clearGoTo,
      onOpenAuth,
      onCloseAuth,
      getProfile,
      mergeCart,
      onSignIn,
      onSignOut,
      openSocket,
      closeSocket,
      checkToken,
    };
  })
  .actions((self) => {
    const mount = (
      { isAuthorized, isMobileApp }: Params,
      actions: Actions,
      captions?: any,
      isLoading?: boolean,
      cartItems?: any,
    ) => {
      self.isAuthorized = isAuthorized;
      self.isMobileApp = isMobileApp;
      self.isLoading = isLoading;
      self.externalActions = actions;
      self.captions = captions;
      self.data.cartItems = cartItems;
      self.setSettings();
      self.checkToken();
      self.mounted = true;
    };

    const unmount = () => {
      clearTimeout(refreshTimer);
      self.mounted = false;
      self.initialized = false;
      self.pathname = null;
      self.isAuthorized = false;
      self.isMobileApp = false;
      self.isLoading = false;
      self.error = null;
      self.captions = null;
      self.goTo = null;
      self.auth = null;
      self.settings = null;
      self.channel = null;
      self.data = { profile: null, cartItems: null };
    };

    const update = ({ isAuthorized, isMobileApp }: Params, captions?: any, isLoading?: boolean, cartItems?: any) => {
      self.isAuthorized = isAuthorized;
      self.isMobileApp = isMobileApp;
      self.isLoading = isLoading;
      self.captions = captions;
      self.data.cartItems = cartItems;
    };

    return {
      mount,
      unmount,
      update,
    };
  });

export const store = Store.create({
  mounted: false,
  initialized: false,
  pathname: null,
  isAuthorized: false,
  isMobileApp: null,
  isLoading: null,
  error: null,
  captions: null,
  goTo: null,
  auth: null,
  settings: null,
  data: { profile: null },
});

export const StoreContext = React.createContext(store);

export const useStore = () => React.useContext(StoreContext);

export interface IStore extends Instance<typeof Store> {}

export function injectStore<T extends ContainerStore>(containerId: string, containerStore: T) {
  store.containers[containerId] = containerStore;
  containerStore.root.store = store;
}
