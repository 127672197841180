// @ts-nocheck
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import smoothscroll from 'smoothscroll-polyfill';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { save, load } from 'redux-localstorage-simple';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ChakraProvider } from '@chakra-ui/react';
import TagManager from 'react-gtm-module';

import rootReducer from './redux/reducers/rootReducer';
import * as serviceWorker from './serviceWorker';
import './assets/scss/style.scss';
import App from './App';
import Fonts from './styles/global';
import theme from './styles/theme';
import './styles/style.scss';

import { store as mstStore, StoreContext } from './store';

const tagManagerArgs = { gtmId: process.env.REACT_APP_GTM_ID };
TagManager.initialize(tagManagerArgs);

const searchParams = new URLSearchParams(window.location.search);
const reduxData = load();
if (reduxData?.productData?.categoryid) {
  Object.assign(reduxData.productData, {
    categoryid: searchParams.get('categoryIds') ?? reduxData?.productData?.categoryid,
  });
}

const store = createStore(
  rootReducer,
  reduxData,
  composeWithDevTools(applyMiddleware(thunk, save({ ignoreStates: ['error', 'settings', 'cartData', 'goTo'] }))),
);

smoothscroll.polyfill();
const container = document.querySelector('#root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <StoreContext.Provider value={mstStore}>
      <ChakraProvider theme={theme}>
        <Fonts />
        <App />
      </ChakraProvider>
    </StoreContext.Provider>
  </Provider>,
);

serviceWorker.unregister();
