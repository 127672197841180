import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import IconClose from 'components/icons/IconClose';
import { H5 } from '../Heading';

import { ModalProps } from './types';
import {
  StyledModal,
  StyledModalInner,
  StyledModalHeader,
  StyledModalContent,
  StyledModalTitle,
  StyledModalClose,
} from './style';
import { sendCloseEvent, sendOpenEvent } from './utils';

const Modal: React.FC<ModalProps> = (props) => {
  const {
    zIndex = 10,
    width,
    height,
    minWidth,
    minHeight,
    maxWidth = '100%',
    maxHeight = '100%',
    size,
    font,
    color,
    backgroundColor,
    headerColor,
    headerBackgroundColor,
    contentColor,
    padding = 'm',
    contentBackgroundColor,
    borderRadius,
    onClose,
    closingOnOutClick = false,
    closingOnEscPress = false,
    withoutCloseIcon = false,
    preventDefault = false,
    className,
    title,
    toggleEvent = false,
    children,
  } = props;

  const innerNode = useRef(null);
  const bodyNode = document.querySelector('body');
  const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

  const handleClose = (e?: any) => {
    if (toggleEvent) sendCloseEvent();
    if (e && preventDefault) {
      onClose(e);
    } else onClose();
  };

  const handleClick = (e: MouseEvent | TouchEvent) => {
    if (closingOnOutClick && onClose && !innerNode.current.contains(e.target)) {
      e.preventDefault();
      handleClose();
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (closingOnEscPress && onClose && e.which === 27) {
      handleClose();
    }
  };

  useEffect(() => {
    if (toggleEvent) sendOpenEvent();
    document.addEventListener('click', handleClick, true);
    document.addEventListener('touchend', handleClick, true);
    document.addEventListener('keydown', handleKeyPress);
    bodyNode.style.overflow = 'hidden';
    bodyNode.style.marginRight = `${scrollbarWidth}px`;

    return () => {
      document.removeEventListener('click', handleClick, true);
      document.removeEventListener('touchend', handleClick, true);
      document.removeEventListener('keypress', handleKeyPress);
      bodyNode.style.overflow = '';
      bodyNode.style.marginRight = '';
      if (toggleEvent) sendCloseEvent();
    };
  }, []);

  return createPortal(
    <StyledModal sZIndex={zIndex} className={className}>
      <StyledModalInner
        ref={innerNode}
        sWidth={width}
        sHeight={height}
        sMinWidth={minWidth}
        sMinHeight={minHeight}
        sMaxWidth={maxWidth}
        sMaxHeight={maxHeight}
        sSize={size}
        sFont={font}
        sColor={color}
        sBackgroundColor={backgroundColor}
        sBorderRadius={borderRadius}
      >
        {!!title && (
          <StyledModalHeader sColor={headerColor} sBackgroundColor={headerBackgroundColor}>
            <StyledModalTitle>
              <H5 font="primary" fontWeight="medium" fontStretch="condensed" color="grey" whiteSpace="preWrap">
                {title}
              </H5>
            </StyledModalTitle>
          </StyledModalHeader>
        )}
        {!!onClose && !withoutCloseIcon && (
          <StyledModalClose onClick={(e) => handleClose(e)}>
            <IconClose display="block" />
          </StyledModalClose>
        )}
        <StyledModalContent
          id="ModalContent"
          sPadding={padding}
          sColor={contentColor}
          sBackgroundColor={contentBackgroundColor}
        >
          {children}
        </StyledModalContent>
      </StyledModalInner>
    </StyledModal>,
    bodyNode,
  );
};

export default Modal;
