import {
  mediaSizes,
  fonts,
  colors,
  fontWeights,
  fontStyles,
  textAligns,
  fontStretches,
  whiteSpace,
  textTransform,
} from 'components/constants';

export default {
  font: { ...fonts },
  color: { ...colors },
  fontWeight: { ...fontWeights },
  fontStyle: { ...fontStyles },
  fontStretch: { ...fontStretches },
  textAlign: { ...textAligns },
  whiteSpace: { ...whiteSpace },
  textTransform: { ...textTransform },
  size: {
    [mediaSizes.s]: { xxs: 8, xs: 10, s: 12, m: 14, l: 16, xl: 16 },
    [mediaSizes.m]: { xxs: 8, xs: 10, s: 13, m: 15, l: 18, xl: 20 },
    [mediaSizes.l]: { xxs: 8, xs: 10, s: 14, m: 16, l: 20, xl: 24 },
  },
};
